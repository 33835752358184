import { CreateLoginFormProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps: CreateLoginFormProps['classProps'] = {
  root: 'flex w-full items-center h-full',
  form: 'gap-5 text-left text-white w-full',
  info: 'text-white/80 px-3 text-xs mt-2 leading-normal',
  email: 'text-secondary',
  submitButtonWrapper: 'flex justify-stretch w-full',
  submitButtonBackWrapper: '!border-white/40 hover:!border-secondary w-full',
  errorMessage: 'text-secondary w-full',
};

export default classProps;

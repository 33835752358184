import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { initialStateUserFormPW as PasswordState, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { getCsrfToken, signIn, SignInOptions, useSession } from 'next-auth/react';
import { CreateUserFormPW, AuthTypeCheck, CreateUserForm, LoginForm } from '@components/organisms/Authentication';
import { DefaultOnlyLogo } from '@components/templates/layouts/DefaultOnlyLogo';
import {
  CustomerRegisterFromCustomerDocument,
  CustomerRegisterUnconfirmedDocument,
  CreateUserAccountDocument,
  MutationCustomerRegisterUnconfirmedArgs,
  MutationCustomerRegisterFromCustomerArgs,
  MutationCreateUserAccountArgs,
  UserSettingPortal,
  CustomerSalutation,
  CustomerLoginPreDocument,
  ResendActivationLinkDocument,
  CreateTvmFavoriteMultipleDocument,
  RemoveTvmFavoriteMultipleDocument,
} from '@src/lib/graphql/generated';
import { client, ssrMutation, useMutation } from '@src/lib/graphql/urql';
import { SectionWrapper } from '../SectionWrapper/SectionWrapper';

const getSalutation = (gender: string) => {
  switch (gender) {
    case 'male':
      return CustomerSalutation.Herr;
    case 'female':
      return CustomerSalutation.Frau;
    case 'family':
      return CustomerSalutation.An;
    case 'company':
      return CustomerSalutation.Firma;
    default:
      return CustomerSalutation.An;
  }
};

type LoginState = {
  email: string;
  password: string;
};

export type LoginStep =
  | 'start'
  | 'login'
  | 'unconfirmed'
  | 'unconfirmed_error'
  | 'register'
  | 'registerName'
  | 'nopassword_error'
  | 'registrationFinish';

export interface LoginFlowProps extends PropsWithClassProps {
  step: LoginStep;
  onStepChange: (step: LoginStep) => void;
  loginSuccessCallback?: () => void;
  explicitCallbackUrl?: string;
}

export const LoginFlow = ({
  step,
  onStepChange,
  loginSuccessCallback,
  explicitCallbackUrl,
  classProps,
}: LoginFlowProps) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { update: updateSession } = useSession();

  const [email, setEmail] = useState('');
  const [passwordForm, setPasswordForm] = useState<PasswordState>({
    email: '',
    password: '',
    passwordConfirmation: '',
    disclaimer: false,
  });

  const [, executeMutationCreateFavorites] = useMutation(CreateTvmFavoriteMultipleDocument);
  const [, executeMutationRemoveFavorites] = useMutation(RemoveTvmFavoriteMultipleDocument);

  async function checkEmail(email: string) {
    const { data: checkMail } = await client
      .mutation(CustomerLoginPreDocument, {
        email: email,
        password: 'password',
      })
      .toPromise();
    setEmail(email);
    if (checkMail?.customerLoginPre?.error_code == 4017) {
      const resent = await resendActivationLink(email);
      if (resent) {
        onStepChange('unconfirmed');
      } else {
        onStepChange('unconfirmed_error');
      }
    } else if (checkMail?.customerLoginPre?.error_code == 4019) {
      setError('Kein gültiger Benutzername oder Passwort.');
      onStepChange('nopassword_error');
    }
    return checkMail?.customerLoginPre.error_code;
  }
  async function resendActivationLink(email: string) {
    if (email) {
      const { data } = await client
        .mutation(ResendActivationLinkDocument, {
          email: email,
        })
        .toPromise();
      return data?.resendActivationLink?.success;
    } else {
      return false;
    }
  }
  async function registerSubscriber(data: MutationCustomerRegisterFromCustomerArgs) {
    const { data: subscriptionData } = await ssrMutation({
      query: CustomerRegisterFromCustomerDocument,
      variables: {
        email: data.email,
        lastName: data.lastName,
        postalCode: data.postalCode,
        customerId: data.customerId,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation || '',
      },
    });

    return subscriptionData;
  }

  async function createUser(data: MutationCustomerRegisterUnconfirmedArgs) {
    const { data: registrationData } = await ssrMutation({
      query: CustomerRegisterUnconfirmedDocument,
      variables: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        terms: data.terms,
        salutation: data.salutation,
        portal: data.portal,
      },
    });

    return registrationData;
  }

  async function createUserWorkflow(data: MutationCreateUserAccountArgs) {
    return await client
      .mutation(CreateUserAccountDocument, {
        email: data.email || '',
        firstName: data.firstName,
        lastName: data.lastName,
        salutation: data.salutation,
        portal: data.portal as UserSettingPortal,
      })
      .toPromise();
  }

  async function register(data) {
    setLoading(true);
    try {
      const userPayload = {
        email: passwordForm.email,
        lastName: data.lastName,
        firstName: data.firstName,
        password: passwordForm.password,
        passwordConfirmation: passwordForm.passwordConfirmation,
        terms: passwordForm.disclaimer,
      };

      if (data.isSubscriptionChecked) {
        // handle connect subscription
        const subscriptionData = await registerSubscriber({
          ...userPayload,
          lastName: String(data.lastNameSubscription),
          postalCode: String(data.postalCode),
          customerId: String(data.customerId),
        });

        if (subscriptionData?.customerRegisterFromCustomer?.success) {
          router.push('/auth/thanks');
          return { error: false };
        }
        const error = subscriptionData?.customerRegisterFromCustomer?.error_code;
        if (error && error > -1) {
          setError(errors[error] ?? 'Unbekannter Fehler');
          setLoading(false);
          return { error: true, message: errors[error] ?? 'Unbekannter Fehler' };
        }

        setError(subscriptionData?.customerRegisterFromCustomer?.error_text || '');
        setLoading(false);
        return { error: true, message: subscriptionData?.customerRegisterFromCustomer?.error_text };
      }

      const createdUserData = await createUser({
        ...userPayload,
        salutation: getSalutation(data.gender),
        portal: UserSettingPortal.Tvmedia,
      });

      if (createdUserData?.customerRegisterUnconfirmed?.success) {
        const { data: userWorkflowData } = await createUserWorkflow({
          email: passwordForm.email,
          lastName: data.lastName,
          firstName: data.firstName,
          salutation: getSalutation(data.gender),
          subject: 'Bestätigung deiner Registrierung auf www.tv-media.at',
          portal: UserSettingPortal.Tvmedia,
        });
        if (userWorkflowData?.createUserAccount?.success) {
          setLoading(false);
          onStepChange('registrationFinish');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return { error: false };
        }
        setLoading(false);
        setError(userWorkflowData?.createUserAccount?.error_text || 'Unbekannter Fehler im User Worflow.');
        return {
          error: true,
          message: userWorkflowData?.createUserAccount?.error_text || 'Unbekannter Fehler im User Worflow.',
        };
      }
      setLoading(false);
      setError(createdUserData?.customerRegisterUnconfirmed?.error_text || 'Unbekannter Fehler im User Worflow.');
      return { error: true, message: createdUserData?.customerRegisterUnconfirmed?.error_text };
    } catch (error) {
      setLoading(false);
      console.error('Error in registration:', error);
      setError('Unexpected error occurred.');
      return { error: true, message: 'Unexpected error occurred.' };
    }
  }

  async function updateLocalFavorites() {
    const changesData = localStorage.getItem('favChanges');
    if (!changesData) return;
    let changes = JSON.parse(changesData);

    const additions = changes.filter((e) => e.action === 'add')?.map((e) => ({ entity: e.id, type: e.type }));
    if (additions.length > 0) {
      await executeMutationCreateFavorites({ data: additions })
        .then(() => {
          changes = changes.filter((e) => e.action !== 'add');
        })
        .catch((err) => console.error(err));
    }

    const removals = changes.filter((e) => e.action === 'remove')?.map((e) => ({ entity: e.id, type: e.type }));
    if (removals.length > 0) {
      await executeMutationRemoveFavorites({ entityIds: removals })
        .then(() => {
          changes = changes.filter((e) => e.action !== 'remove');
        })
        .catch((err) => console.error(err));
    }
    localStorage.setItem('favChanges', JSON.stringify(changes));
  }

  return (
    <SectionWrapper classProps={{ root: classProps?.root }}>
      {step === 'start' ? (
        <AuthTypeCheck
          errorMessage={error}
          checkEmailExternal={(email) => checkEmail(email)}
          submitHandler={(exists) => {
            if (exists == 0) {
              onStepChange('login');
            } else if (exists && exists < 0) {
              onStepChange('register');
            }
          }}
          info={
            <>
              Falls Du noch keinen Account hast, wirst Du automatisch zur Registrierung weitergeleitet. Du kannst dich
              auch mit anderen&nbsp;
              <Link href="https://www.vgn.at/medienmarken">
                <span className="link font-bold">VGN Zugängen</span>
              </Link>
              &nbsp;anmelden.
            </>
          }
        />
      ) : step === 'login' ? (
        <LoginForm
          initialState={{ email: email, password: '', email_disabled: false }}
          errorMessage={error && SignInError({ error: error })}
          isLoading={loading}
          buttonProps={{ preventDefault: true, stopPropagation: true }}
          submitHandler={async (data: LoginState) => {
            const csrfToken = await getCsrfToken();
            setLoading(true);

            const signInOptions: SignInOptions = {
              email: data.email,
              password: data.password,
              redirect: false,
              portal: 'tvmedia',
              csrfToken,
            };

            if (explicitCallbackUrl) {
              signInOptions.callbackUrl = explicitCallbackUrl;
            }

            await signIn('credentials', signInOptions)
              .then(async (result) => {
                if (result?.error) {
                  setError(result?.error || '');
                  console.error(result.error);
                  return;
                }
                await updateLocalFavorites();
                await updateSession();
                !!loginSuccessCallback && loginSuccessCallback();
              })
              .catch((err) => {
                console.error(err);
                setError(err);
              });
            setLoading(false);
          }}
          onBackAction={() => onStepChange('start')}
        />
      ) : step === 'register' ? (
        <CreateUserFormPW
          initialState={{ email: email, password: '', passwordConfirmation: '', disclaimer: false }}
          submitHandler={(data: PasswordState) => {
            setPasswordForm(data);
            onStepChange('registerName');
          }}
          disclaimerProps={{
            id: 'disclaimer',
            label: (
              <>
                Ich stimme zu, dass die VGN Medien Holding GmbH, die VGN Digital GmbH, die Woman GmbH & Co KG, die Gusto
                GmbH & Co KG und die Verlagsgruppe News Medienservice GmbH meine oben genannten personenbezogenen Daten
                zur Übermittlung von Informations- und Werbematerial verarbeitet. Nähere Informationen zur Verarbeitung
                meiner personenbezogenen Daten erhalte ich in der&nbsp;
                <Link href="https://www.vgn.at/Datenschutzpolicy" legacyBehavior className="underline">
                  Datenschutzerklärung
                </Link>
              </>
            ),
          }}
          onBackAction={() => onStepChange('login')}
        />
      ) : step === 'registerName' ? (
        <>
          <CreateUserForm
            submitHandler={register}
            isLoading={loading}
            onBackAction={() => onStepChange('register')}
            subscriberProps={{
              id: 'subscribe',
              label: <>Du bist bereits ein Kunde für unser Print-Abo und möchtest es mit deinem Account verknüpfen?</>,
            }}
            infoSubscription={null}
          />
          {error && <div className="mt-6 text-sm font-bold text-primary">{error}</div>}
        </>
      ) : step === 'unconfirmed' ? (
        <></>
      ) : step === 'unconfirmed_error' ? (
        <></>
      ) : null}
    </SectionWrapper>
  );
};

const error_messages = {
  EmailVerifiedFalse: 'E-Mail Adresse wurde noch nicht bestätigt.',
  EmailSignin: 'Überprüfe deinen Posteingang',
  CredentialsSignin: 'Anmeldung fehlgeschlagen. Bitte überprüfe deine Zugangsdaten.',
  TooManyLoginAttempts: 'Zuviele Anmeldeversuche. Probier es später noch einmal.',
  default: 'Konnte nicht angemeldet werden.',
};

type ErrorCodes = {
  [key: number]: string;
};

const errors: ErrorCodes = {
  4001: 'Unter der angegebenen Kundennumner existiert bereits ein Benutzerkonto. Bei Fragen wende dich an unser Service Center unter 01/9555 100 oder per E-Mail an help@meinabo.at.',
  4003: 'Diese E-Mail Adresse ist bereits in Verwendung. Bitte wende dich an unser Service Center unter 01/9555 100 oder per E-Mail an help@meinabo.at.',
  4002: 'Beim Matching der PLZ, des Nachnamens oder der Kundennnummer gab es ein Problem. Bitte wenden dich an unser Service Center unter 01/9555 100 oder per E-Mail an help@meinabo.at.',
  4004: 'Zu viele Versuche. Bitte probier es später noch einmal.',
  4005: 'Ein (unbekannter) Fehler ist aufgetreten. Bitte probier es später noch einmal.',
};

const SignInError = (error: { error: string | undefined }) => {
  const key = error.error || 'default';
  const keyTyped = key as keyof typeof error_messages;
  const errorMessage = error_messages[keyTyped];
  return errorMessage;
};

LoginFlow.PageLayout = DefaultOnlyLogo;
export default LoginFlow;

import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import {
  CreateTvmFavoriteDocument,
  CreateTvmFavoriteMultipleDocument,
  RemoveTvmFavoriteDocument,
  TvmFavorite,
  TvmFavoriteEntity,
  TvmFavoritesSimpleDocument,
  TvmFavoriteType,
} from '@src/lib/graphql/generated';
import { ssrMutation, ssrQuery } from '@src/lib/graphql/urql';

const TVM_FAVORITE_MAPPINGS = {
  TVChannel: TvmFavoriteType['Channel'],
  TVChannelShowtime: TvmFavoriteType['ChannelShowtime'],
  Movie: TvmFavoriteType['Movie'],
  Provider: TvmFavoriteType['Provider'],
  Show: TvmFavoriteType['Tvshow'],
  Cinema: TvmFavoriteType['Cinema'],
};

type FavoriteInputType = {
  type: TvmFavoriteEntity['__typename'];
  id: TvmFavoriteEntity['id'];
  onlyLocal?: boolean;
  isTemp?: boolean;
};

type FavoritesStore = {
  favorites: TvmFavorite[];
  tempFavorites: TvmFavorite[];
  lastFetched: Date;
  getFavoriteState: (id: string, isTemp?: boolean) => boolean;
  clearFavorites: () => void;
  addFavorite: ({ type, id }: FavoriteInputType) => void;
  addMultipleFavorites: ({
    favorites,
    onlyLocal,
    callbackFn,
  }: {
    favorites: TvmFavorite[];
    onlyLocal?: boolean;
    callbackFn?: () => void;
  }) => void;
  removeFavorite: ({ type, id }: FavoriteInputType) => void;
  toggleFavorite: ({ type, id }: FavoriteInputType) => void;
  setFavorites: ({ favorites, isTemp }: { favorites: TvmFavorite[]; isTemp: boolean }) => void;
  getTypeFavorites: (type: TvmFavoriteEntity['__typename']) => TvmFavorite[];
  handleTempFavorites: (callbackFn?: () => void) => void;
  fetchFavorites: (callbackFn?: () => void) => void;
};

export const useFavoritesStore = create<FavoritesStore>()(
  persist(
    subscribeWithSelector((set, get) => ({
      favorites: [],
      tempFavorites: [],
      lastFetched: null,
      getFavoriteState: (id, isTemp) => {
        const entries = isTemp ? get().tempFavorites : get().favorites;
        return entries
          ?.flatMap((e) => (e.entity?.__typename === 'TVChannelShowtime' ? e.entity?.event_id : e.entity?.id))
          .includes(id);
      },
      addFavorite: ({ type, id, onlyLocal, isTemp }) => {
        if (get().getFavoriteState(id, isTemp)) return;
        set((state) => ({
          ...state,
          [isTemp ? 'tempFavorites' : 'favorites']: [
            ...(isTemp ? state.tempFavorites : state.favorites),
            {
              entity: {
                id: type === 'TVChannelShowtime' ? undefined : id,
                event_id: type === 'TVChannelShowtime' ? id : undefined,
                __typename: type,
              },
            },
          ],
        }));
        if (!onlyLocal && !isTemp) {
          ssrMutation({
            query: CreateTvmFavoriteDocument,
            variables: {
              type: TVM_FAVORITE_MAPPINGS[type],
              entity: type !== 'TVChannelShowtime' ? id : undefined,
              showtime: type === 'TVChannelShowtime' ? id : undefined,
            },
            context: {
              fetchOptions: {
                credentials: 'include',
              },
            },
          }).catch((err) => {
            console.error(err);
            get().addFavorite({ type, id, onlyLocal: true });
          });
        }
      },
      addMultipleFavorites: ({ favorites, onlyLocal, callbackFn }) => {
        const filteredFavorites = favorites.filter((e) => !get().getFavoriteState(e?.id));
        set((state) => ({
          ...state,
          favorites: [
            ...state.favorites,
            ...filteredFavorites.map((e) => ({
              entity: {
                id: e?.entity?.__typename === 'TVChannelShowtime' ? undefined : e?.entity?.id,
                event_id: e?.entity?.__typename === 'TVChannelShowtime' ? e?.entity?.event_id : undefined,
                __typename: e?.entity?.__typename,
              },
            })),
          ],
        }));
        if (!onlyLocal) {
          ssrMutation({
            query: CreateTvmFavoriteMultipleDocument,
            variables: {
              data: filteredFavorites.map((e) => ({
                entity: e?.entity?.__typename === 'TVChannelShowtime' ? undefined : e?.entity?.id,
                showtime: e?.entity?.__typename === 'TVChannelShowtime' ? e?.entity?.event_id : undefined,
                type: TVM_FAVORITE_MAPPINGS[e?.entity?.__typename],
              })),
            },
            context: {
              fetchOptions: {
                credentials: 'include',
              },
            },
          })
            .then(() => {
              !!callbackFn && callbackFn();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      },
      removeFavorite: ({ type, id, onlyLocal, isTemp }) => {
        set((state) => ({
          ...state,
          [isTemp ? 'tempFavorites' : 'favorites']: [
            ...state[isTemp ? 'tempFavorites' : 'favorites'].filter((e) =>
              e.entity?.__typename === 'TVChannelShowtime' ? e.entity?.event_id !== id : e.entity?.id !== id,
            ),
          ],
        }));
        if (!onlyLocal && !isTemp) {
          ssrMutation({
            query: RemoveTvmFavoriteDocument,
            variables: {
              entity: type !== 'TVChannelShowtime' ? id : undefined,
              showtime: type === 'TVChannelShowtime' ? id : undefined,
            },
            context: {
              fetchOptions: {
                credentials: 'include',
              },
            },
          }).catch((err) => {
            console.error(err);
            get().addFavorite({ type, id, onlyLocal: true });
          });
        }
      },
      toggleFavorite: ({ type, id, onlyLocal, isTemp }) => {
        const isFavorite = get().getFavoriteState(id, isTemp);
        if (isFavorite) {
          get().removeFavorite({ type, id, onlyLocal, isTemp });
        } else {
          get().addFavorite({ type, id, onlyLocal, isTemp });
        }
      },
      setFavorites: async ({ favorites, isTemp }) => {
        if (isTemp) {
          set({ tempFavorites: favorites });
        } else {
          set({ favorites });
        }
      },
      handleTempFavorites: (callbackFn) => {
        const entries = get().tempFavorites;
        if (entries?.length > 0) {
          get().addMultipleFavorites({
            favorites: entries,
            callbackFn: () => {
              set({ tempFavorites: [] });
              callbackFn?.();
            },
          });
        } else {
          callbackFn?.();
        }
      },
      getTypeFavorites: (type) => {
        return get().favorites.filter((e) => e.entity?.__typename === type);
      },
      fetchFavorites: async (callbackFn) => {
        const { data: favoritesData } = await ssrQuery({
          query: TvmFavoritesSimpleDocument,
          variables: {
            includeTypes: [
              TvmFavoriteType.Movie,
              TvmFavoriteType.Tvshow,
              TvmFavoriteType.ChannelShowtime,
              TvmFavoriteType.Channel,
              TvmFavoriteType.Provider,
            ],
            first: 999,
            page: 1,
          },
          context: {
            fetchOptions: {
              credentials: 'include',
            },
          },
        });
        set({ favorites: favoritesData?.tvmFavorites?.data, lastFetched: new Date() });
        callbackFn?.();
      },
      clearFavorites: async () => {
        set({ favorites: [], lastFetched: null });
      },
    })),
    {
      name: 'favorites-store',
      partialize: (state) => ({
        tempFavorites: state.tempFavorites,
        favorites: state.favorites,
        lastFetched: state.lastFetched,
      }),
    },
  ),
);

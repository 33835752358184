import React from 'react';
import type { CreateUserFormProps as CreateUserFormPropsDefault } from '@vgn-medien-holding/vgn-fe-components';
import { CreateUserForm as DefaultCreateUserForm, composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { buttonProps } from '@components/atoms/Button/props/default';
import checkboxStyle from '@components/atoms/Checkbox/styles/default';
import dropdownStyle from '@components/atoms/Dropdown/styles/bordered';
import inputStyle from '@components/atoms/Input/styles/bordered';
import { Spinner } from '@components/atoms/Spinner/Spinner';
import formStyle from './styles/default';

export interface CreateUserFormProps extends Omit<CreateUserFormPropsDefault, 'initialState'> {
  onBackAction: () => void;
}

export function CreateUserForm({
  classProps,
  submitHandler,
  onBackAction,
  isLoading,
  subscriberProps,
  infoSubscription,
}: CreateUserFormProps & { isLoading?: boolean }) {
  return (
    <DefaultCreateUserForm
      classProps={composeStyles(formStyle, classProps)}
      checkboxClassProps={checkboxStyle}
      inputClassProps={inputStyle}
      initialState={{
        firstName: '',
        lastName: '',
        gender: '',
      }}
      dropdownProps={{ classProps: dropdownStyle }}
      submitHandler={submitHandler}
      buttonProps={{ ...buttonProps.primary, text: 'Weiter' }}
      buttonBackProps={{
        ...buttonProps.secondary,
        text: 'Zurück',
        onAction: onBackAction,
        PreElement: isLoading && <Spinner iconSize="1em" />,
      }}
      genderExcludes={['company', 'family']}
      subscriberProps={subscriberProps}
      infoSubscription={infoSubscription}
    />
  );
}

import { CheckboxProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps = {
  root: 'flex select-none',
  checkboxWrapper: 'flex-none size-[18px] relative',
  checkbox:
    'bg-transparent block border border-gray-400 rounded focus:!outline-none focus:!ring-0 focus:ring-offset-0 focus:ring-transparent focus:bg-transparent checked:!bg-primary checked:border-primary hover:bg-transparent appearance-none cursor-pointer peer w-full h-full',
  checkmark:
    'text-transparent mt-0.5 pointer-events-none hidden peer-checked:grid absolute inset-0 place-items-center p-1',
  label: 'text-gray-400 ml-2 text-xs',
  errorMessage: 'w-screen text-xs text-red-500',
} as CheckboxProps['classProps'];

export default classProps;

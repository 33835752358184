import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import {
  GetUserSettingsDocument,
  SetUserSettingDocument,
  UserSetting,
  UserSettingPortal,
} from '@src/lib/graphql/generated';
import { ssrMutation, ssrQuery } from '@src/lib/graphql/urql';

type LoginStore = {
  loginFlowOpen: boolean;
  loginFlowTitle: string;
  userSettings: UserSetting[];
  setUserSetting: (setting: { key: string; value: string; type: UserSetting['type'] }) => void;
  getUserSetting: (key: string) => UserSetting['value'] | null;
  onCloseCallback: () => void;
  fetchUserSettings: () => void;
  updateLoginStore: (partial: Partial<LoginStore>) => void;
};

export const useLoginStore = create<LoginStore>()(
  persist(
    subscribeWithSelector((set, get) => ({
      loginFlowOpen: false,
      loginFlowTitle: null,
      userSettings: [],
      getUserSetting: (key) => {
        return get().userSettings?.find((setting) => setting.key === key)?.value?.value;
      },
      setUserSetting: async (setting) => {
        const { data } = await ssrMutation({
          query: SetUserSettingDocument,
          variables: {
            data: {
              key: setting.key,
              value: setting.value + '',
              portal: UserSettingPortal.Tvmedia,
              type: setting.type,
            },
          },
          context: {
            fetchOptions: {
              credentials: 'include',
            },
          },
        });
        set({ userSettings: [...get().userSettings.filter((e) => e.key !== setting.key), data?.setUserSetting] });
      },
      fetchUserSettings: async () => {
        const { data: settingsData } = await ssrQuery({
          query: GetUserSettingsDocument,
          variables: {
            portal: UserSettingPortal.Tvmedia,
          },
          context: {
            fetchOptions: {
              credentials: 'include',
            },
          },
        });
        set({ userSettings: settingsData?.userSettings });
      },
      onCloseCallback: () => set({ loginFlowOpen: false }),
      updateLoginStore: (partial) => set(partial),
    })),
    {
      name: 'login-user-store',
      partialize: (state) => ({
        userSettings: state.userSettings,
      }),
    },
  ),
);

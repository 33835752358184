import type { CreateUserFormPWProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps: CreateUserFormPWProps['classProps'] = {
  root: '',
  form: 'grid gap-5 text-left text-gray-200 w-full mt-6',
  password: '',
  passwordConfirmation: '',
  buttonWrapper: 'flex flex-col-reverse *:flex-none *:w-full flex-wrap gap-3',
  errorMessage: 'text-red-500 w-full',
  checksPWWrapper: 'text-sm',
  checksPW: 'flex items-center mb-2 text-gray-500',
  checksPWsuccess: 'text-white',
  checkmark: 'size-3.5 mr-2',
};

export default classProps;

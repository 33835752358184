import React from 'react';
import type { CreateLoginFormProps } from '@vgn-medien-holding/vgn-fe-components';
import { AuthTypeCheck as DefaultAuthTypeCheck, composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { buttonProps } from '@components/atoms/Button/props/default';
import inputStyle from '@components/atoms/Input/styles/bordered';
import formStyle from './styles/default';

export function AuthTypeCheck({
  initialState = { email: '' },
  submitHandler,
  checkEmailExternal,
  errorMessage,
  classProps,
  children,
  info,
}: CreateLoginFormProps) {
  return (
    <DefaultAuthTypeCheck
      errorMessage={errorMessage}
      initialState={initialState}
      submitHandler={submitHandler}
      checkEmailExternal={checkEmailExternal}
      classProps={composeStyles(formStyle, classProps)}
      inputClassProps={inputStyle}
      buttonProps={{
        ...buttonProps.primary,
        classProps: composeStyles(buttonProps.primary.classProps, { root: 'w-full mt-10' }),

        text: 'Weiter',
      }}
      info={info}
    >
      {children}
    </DefaultAuthTypeCheck>
  );
}

import React from 'react';
import type { LoginFormProps } from '@vgn-medien-holding/vgn-fe-components';
import { LoginForm as DefaultLoginForm, Link, composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { buttonProps } from '@components/atoms/Button/props/default';
import inputStyle from '@components/atoms/Input/styles/bordered';
import { Spinner } from '@components/atoms/Spinner/Spinner';
import formStyle from './styles/default';

export const LoginForm = ({
  initialState = { email: '', password: '' },
  submitHandler,
  errorMessage,
  onBackAction,
  isLoading,
  classProps,
}: LoginFormProps & { onBackAction?: () => void; isLoading: boolean }) => {
  return (
    <DefaultLoginForm
      errorMessage={errorMessage}
      initialState={initialState}
      submitHandler={submitHandler}
      classProps={composeStyles(formStyle, classProps)}
      inputClassProps={inputStyle}
      buttonProps={{
        ...buttonProps.primary,
        text: 'Anmelden',
        type: 'submit',
        PreElement: isLoading && <Spinner iconSize="1em" />,
      }}
      buttonBackProps={{ ...buttonProps.secondary, text: 'Zurück', hasAction: true, onAction: onBackAction }}
    >
      <Link
        href="/auth/password-reset"
        title="Passwort vergessen?"
        classProps={{ root: 'text-right text-sm text-[#979797] underline underline-offset-2' }}
      >
        Passwort vergessen?
      </Link>
    </DefaultLoginForm>
  );
};

import { InputPasswordProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps = {
  root: 'w-full',
  eyeIcon: 'text-white mt-0',
  inputWrapper: 'w-full relative grid',
  input: 'order-2 placeholder:opacity-0 rounded-full bg-transparent text-white',
  label: 'order-1 label-base mb-2 pl-2 text-gray-400 text-left',
  errorMessage: 'ml-5 mt-1 text-xs text-primary tracking-wide',
  additionalInfo: 'ml-5 mt-1 text-xs text-gray-500',
} as InputPasswordProps['classProps'];

export default classProps;

import type { CreateUserFormProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps: CreateUserFormProps['classProps'] = {
  root: '',
  form: 'grid gap-5 text-left text-gray-200 px-4 w-full mt-6',
  buttonWrapper: 'flex flex-col-reverse *:flex-none *:w-full flex-wrap gap-3',
  errorMessage: 'text-red-500 w-full',
  info: 'text-white/80 body-sm',
};

export default classProps;

import { DialogProps } from '@vgn-medien-holding/vgn-fe-components';

export default {
  root: 'transition duration-300 ease-out data-[closed]:opacity-0 text-center z-[100] pointer-events-auto',
  backdrop: 'bg-black/60',
  dialog: 'max-w-lg w-full p-10 pt-0 bg-gray-800 text-white rounded-2xl shadow-lg relative',
  buttonContainer: 'absolute top-0 right-0 pt-4 px-4',
  button: 'text-white pointer-events-auto',
  title: 'heading-4 mb-2.5 pt-10 text-white',
  description: 'text-body-base',
} satisfies DialogProps['classProps'];

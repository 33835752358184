import React from 'react';
import { Dialog as DialogDefault, DialogProps as DialogPropsDefault } from '@vgn-medien-holding/vgn-fe-components';
import { IconClose } from '@src/assets/icon-close';
import defaultStyles from './styles/default';

export interface DialogProps extends DialogPropsDefault {
  title: string;
  description?: string;
  onClose?: () => void;
  isOpen?: boolean;
}

export const Dialog = ({ title, description, onClose, isOpen, children }: DialogProps) => {
  return (
    <DialogDefault
      title={title}
      description={description}
      isOpen={isOpen}
      noPortal
      onClose={() => {
        onClose();
      }}
      showCloseButton
      closeIcon={() => <IconClose classProps={{ root: 'text-white size-4' }} />}
      classProps={defaultStyles}
    >
      {children}
    </DialogDefault>
  );
};

import { LoginFormProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps: LoginFormProps['classProps'] = {
  root: 'flex items-center h-full w-full',
  form: 'gap-5 grid text-left text-white px-4 w-full',
  password: '',
  email: '',
  buttonWrapper: 'flex space-x-reverse *:flex-none *:w-full flex-wrap gap-3 mt-10',
  errorMessage: 'text-secondary w-full',
};

export default classProps;

import React, { useState } from 'react';
import { usePathname } from 'next/navigation';
import { Button } from '@components/atoms/Button/Button';
import { Dialog } from '@components/molecules/Dialog/Dialog';
import { useLoginStore } from '@src/stores/login';
import LoginFlow, { LoginStep } from '../LoginFlow/LoginFlow';

export const OverlayLoginFlow = () => {
  const { loginFlowTitle, loginFlowOpen, onCloseCallback } = useLoginStore((state) => ({
    loginFlowTitle: state.loginFlowTitle,
    loginFlowOpen: state.loginFlowOpen,
    onCloseCallback: state.onCloseCallback,
  }));
  const [step, setStep] = useState<LoginStep>('start');
  const pathname = usePathname();

  const steps = {
    start: {
      title: loginFlowTitle,
      content: <>Melde Dich an oder erstelle einen kostenlosen Account.</>,
    },
    get login() {
      return this.start;
    },
    register: {
      title: 'Erstelle einen kostenlosen Account.',
      content: 'Gib Deine E-Mail-Adresse ein und wähle ein Passwort.',
    },
    registerName: {
      title: 'Sag uns noch, wie Du heißt.',
      content:
        'Bitte gib Deinen Vor- und Nachnamen an. Bist Du bereits Kunde für unser Print-Abo, dann kannst Du diesen hier verknüpfen.',
    },
    unconfirmed: {
      title: 'Account noch nicht aktiviert.',
      content: (
        <>
          <div className="pt-6">
            Dein E-Mail wurde noch nicht bestätigt. Bitte überprüfe Deinen Posteingang (und Spam) und bestätige den
            Link, den wir Dir nochmals zugesendet haben.
          </div>
        </>
      ),
    },
    registrationFinish: {
      title: 'Fast geschafft!',
      content: (
        <>
          <div>
            Du erhältst in Kürze eine Bestätigungsmail.
            <br />
            Bis dahin wird deine Auswahl gespeichert.
          </div>
          <Button buttonStyle="primary" link="/programm" title="Zum TV-Programm" classProps={{ root: 'mt-8 w-full' }} />
        </>
      ),
    },
    loginFinish: {
      title: 'Willkommen zurück!',
      content: (
        <>
          <div>Genieße jetzt Deine Lieblingssender.</div>
          <Button
            buttonStyle="primary"
            link="/programm"
            title="Zum TV-Programm"
            classProps={{ root: 'mt-16 w-full' }}
          />
          <Button buttonStyle="secondary" link="/favoriten/sender" title="Lieblingssender ändern" />
        </>
      ),
    },
  };

  const activeStep = step in steps ? steps[step] : null;
  return (
    <Dialog title={activeStep?.title} isOpen={loginFlowOpen} onClose={onCloseCallback}>
      {activeStep?.content}
      <LoginFlow
        step={step}
        onStepChange={(step) => setStep(step)}
        classProps={{ root: 'mt-8' }}
        loginSuccessCallback={onCloseCallback}
        explicitCallbackUrl={pathname}
      />
    </Dialog>
  );
};

import React from 'react';
import type { CreateUserFormPWProps } from '@vgn-medien-holding/vgn-fe-components';
import { CreateUserFormPW as DefaultCreateUserFormPW, composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { buttonProps } from '@components/atoms/Button/props/default';
import checkboxStyle from '@components/atoms/Checkbox/styles/default';
import inputStyle from '@components/atoms/Input/styles/bordered';
import inputPasswordStyle from '@components/atoms/InputPassword/styles/default';
import formStyle from './styles/default';

export function CreateUserFormPW({
  classProps,
  initialState = {
    email: '',
    password: '',
    passwordConfirmation: '',
    disclaimer: false,
  },
  submitHandler,
  onBackAction,
  errorMessage,
  disclaimerProps,
}: CreateUserFormPWProps & { onBackAction?: () => void }) {
  return (
    <DefaultCreateUserFormPW
      classProps={composeStyles(formStyle, classProps)}
      checkboxClassProps={checkboxStyle}
      inputClassProps={inputStyle}
      inputPasswordClassProps={inputPasswordStyle}
      initialState={initialState}
      errorMessage={errorMessage}
      submitHandler={submitHandler}
      buttonProps={{ ...buttonProps.primary, text: 'Weiter' }}
      buttonBackProps={{
        ...buttonProps.secondary,
        text: 'Zurück zum Login',
        hasAction: true,
        onAction: onBackAction,
      }}
      disclaimerProps={disclaimerProps}
    />
  );
}

import { InputProps } from '@vgn-medien-holding/vgn-fe-components';

const classProps = {
  root: 'w-full',
  inputWrapper: 'w-full relative grid',
  input:
    'order-2 placeholder:opacity-0 rounded-full bg-transparent text-white focus:border-transparent focus:ring-secondary inline-block min-h-[43px] min-w-0 items-center self-start px-5 py-2 pr-10 text-left text-base',
  label: 'order-1 label-base mb-2 pl-2 text-gray-400 text-left',
  errorMessage: 'ml-5 mt-1 text-xs text-primary tracking-wide',
  errorIcon: 'bottom-3.5 top-auto my-0 text-primary',
  additionalInfo: 'ml-5 mt-1 text-xs text-gray-500',
} as InputProps['classProps'];

export default classProps;
